<template>
  <v-timeline dense clipped class="mb-4 mt-4 lead-timeline">
    <v-slide-x-transition group>
      <v-timeline-item
        v-for="event in timeline"
        :key="event.id"
        class=""
        :color="event.status ? 'primary' : 'secondary'"
        small
      >
        <template v-slot:icon>
          <v-tooltip bottom v-if="event.user">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                :style="{
                  backgroundColor:
                    event.user.profile.avatar && event.user.profile.avatar.url
                      ? 'white'
                      : event.user.profile.color,
                }"
              >
                <img
                  :src="
                    event.user.profile.avatar && event.user.profile.avatar.url
                      ? event.user.profile.avatar.url
                      : event.user.profile.avatarURL
                  "
                  :lazy-src="
                    event.user.profile.avatar && event.user.profile.avatar.url
                      ? event.user.profile.avatar.url
                      : event.user.profile.avatarURL
                  "
                />
              </v-avatar>
            </template>
            <span>{{ event.user.profile.name }}</span>
          </v-tooltip>
        </template>

        <v-row justify="space-between">
          <v-col cols="8">
            <span v-if="event.status">
              Lead Was Moved to the Status:
              <strong>{{ event.status.name }}</strong>
            </span>
            <span v-if="event.comment">
              <strong>{{ event.user.profile.name }}</strong> : <br />
              <span>{{ event.comment.content }}</span>
            </span>
            <span v-if="event.proposal">
              <strong>Proposal Created</strong> : <br />
              <ProposalItemSmall
                :value="event.proposal"
                @click="(val) => $emit('onProposalClick', val)"
              ></ProposalItemSmall>
            </span>
            <v-tooltip bottom v-if="event.document">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="vue-file-agent grid-block-wrapper"
                  style="padding: 0"
                  @click="$emit('onFileClick', event.document)"
                >
                  <strong>File Attached</strong> : <br />

                  <VueFilePreview
                    :value="event.document"
                    :linkable="false"
                    class="file-preview-wrapper grid-box-item grid-block"
                  ></VueFilePreview>
                </div>
              </template>
              <span>{{
                typeof event.document.name === "string"
                  ? event.document.name
                  : event.document.name()
              }}</span>
            </v-tooltip>
          </v-col>
          <v-col class="text-right" cols="4">
            <span>{{ formatDate(event.created_at) }}</span>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-slide-x-transition>
  </v-timeline>
</template>


<script>
import moment from "moment";
import ProposalItemSmall from "./ProposalItemSmall.vue";
export default {
  props: {
    timeline: {
      type: Array,
      default: () => [],
    },
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ProposalItemSmall,
  },
  data() {
    return {
      pTimeline: this.timeline,
      events: [],
      input: null,
      nonce: 0,
    };
  },
  methods: {
    push(message) {
      this.pTimeline.push(message);
    },
    formatDate(date) {
      return moment(date).format("MMMM Do, h:mm a, YYYY");
    },
  },
  watch: {
    timeline: {
      handler(newVal) {
        this.pTimeline = newVal;
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.lead-timeline {
  height: calc(100% - 136px);
  overflow: auto;
  margin-bottom: 0px !important;
}

.grid-block-wrapper {
  cursor: pointer;
  .grid-block {
    min-width: 80px;
    width: 80px;
  }
}
</style>