<template>
  <v-text-field
    class="message-box"
    v-model="message.message"
    filled
    outlined
    :lines="2"
    clear-icon="mdi-close-circle"
    clearable
    label="Message"
    type="text"
    @click:append="addFile"
    @click:append-outer="comment"
    @click:clear="clearMessage"
    @keydown.enter="comment"
    hide-details
  >
    <template v-slot:append>
      <v-fade-transition leave-absolute>
        <v-progress-circular
          v-if="loading"
          size="24"
          color="info"
          indeterminate
        ></v-progress-circular>
        <v-badge
          :value="message.files && message.files.length > 0"
          color="primary"
          :content="message.files.length"
        >
          <v-file-input
            multiple
            v-model="message.files"
            class="ma-0 pa-0 file-input"
            dense
            hide-input
          ></v-file-input>
        </v-badge>
      </v-fade-transition>
    </template>
  </v-text-field>
</template>



<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    events: [],
    nonce: 0,
    message: {
      id: uuidv4(),
      message: "",
      files: [],
    },
  }),

  methods: {
    comment() {
      this.message.time = new Date().toISOString();

      this.$emit("onMessage", this.message);

      this.message = {
        id: uuidv4(),
        message: "",
        files: [],
      };
    },
    clearMessage() {
      this.message.message = "";
    },
    addFile() {},
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  transition: all 1s ease-in-out;
  .file-input {
    height: 10px;
    align-items: center;
  }
}
</style>