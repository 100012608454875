var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-timeline',{staticClass:"mb-4 mt-4 lead-timeline",attrs:{"dense":"","clipped":""}},[_c('v-slide-x-transition',{attrs:{"group":""}},_vm._l((_vm.timeline),function(event){return _c('v-timeline-item',{key:event.id,attrs:{"color":event.status ? 'primary' : 'secondary',"small":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(event.user)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-avatar',_vm._g(_vm._b({style:({
                backgroundColor:
                  event.user.profile.avatar && event.user.profile.avatar.url
                    ? 'white'
                    : event.user.profile.color,
              })},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":event.user.profile.avatar && event.user.profile.avatar.url
                    ? event.user.profile.avatar.url
                    : event.user.profile.avatarURL,"lazy-src":event.user.profile.avatar && event.user.profile.avatar.url
                    ? event.user.profile.avatar.url
                    : event.user.profile.avatarURL}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(event.user.profile.name))])]):_vm._e()]},proxy:true}],null,true)},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"8"}},[(event.status)?_c('span',[_vm._v(" Lead Was Moved to the Status: "),_c('strong',[_vm._v(_vm._s(event.status.name))])]):_vm._e(),(event.comment)?_c('span',[_c('strong',[_vm._v(_vm._s(event.user.profile.name))]),_vm._v(" : "),_c('br'),_c('span',[_vm._v(_vm._s(event.comment.content))])]):_vm._e(),(event.proposal)?_c('span',[_c('strong',[_vm._v("Proposal Created")]),_vm._v(" : "),_c('br'),_c('ProposalItemSmall',{attrs:{"value":event.proposal},on:{"click":(val) => _vm.$emit('onProposalClick', val)}})],1):_vm._e(),(event.document)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"vue-file-agent grid-block-wrapper",staticStyle:{"padding":"0"},on:{"click":function($event){return _vm.$emit('onFileClick', event.document)}}},'div',attrs,false),on),[_c('strong',[_vm._v("File Attached")]),_vm._v(" : "),_c('br'),_c('VueFilePreview',{staticClass:"file-preview-wrapper grid-box-item grid-block",attrs:{"value":event.document,"linkable":false}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(typeof event.document.name === "string" ? event.document.name : event.document.name()))])]):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('span',[_vm._v(_vm._s(_vm.formatDate(event.created_at)))])])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }